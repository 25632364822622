<template lang='pug'>
    validation-observer(v-slot="{ valid }")
      base-material-wizard.custom-tab(:class="`current-tab-${tab}`" v-model="tab" :available-steps="availableSteps" :items="tabs" @click:next="next(valid)" @click:prev="tab--")
        // First
        v-tab-item.account-tab
          form
            .text-center.display-1.font-weight-light.mb-6
            v-row.mx-auto(justify="space-around" style="max-width: 500px;")
              v-col.text-center(cols="auto")
                input.d-none(ref="file" type="file" @change="onChange")
                v-card.mx-auto.mt-0.d-inline-flex.v-card--account( :class="profile.image ? 'success--text' : 'grey--text'" outlined="" height="106" width="106" @click="$refs.file.click()")
                  v-img(v-if='profile.image' :src="profile.image" height="100%" width="100%").custom-avatar
                  v-icon.mx-auto(v-else="" size="96")
                    | mdi-account
                  .v-card--add
                    v-icon()
                      | mdi-plus
              v-col(cols="12" md="6")
                validation-provider(v-slot="{ errors }" rules="required" name="First name")
                  v-text-field(v-model="profile.first_name" :placeholder="userData.providerData[0].displayName" :value="userData.providerData[0].displayName" :error-messages="errors" color="secondary" label="First Name*" prepend-icon="mdi-face" validate-on-blur="")
                validation-provider(v-slot="{ errors }" rules="required" name="Last name")
                  v-text-field(v-model="profile.last_name" :error-messages="errors" color="secondary" label="Last Name*" prepend-icon="mdi-account" validate-on-blur="")
              v-col(cols="12" md="6")
                validation-provider(name="Phone")
                  v-text-field(v-model="profile.phone" :placeholder="userData.providerData[0].phone" color="secondary" label="Phone" prepend-icon="mdi-phone" validate-on-blur="" type="number")
              v-col(cols="12" md="6")
                validation-provider(v-slot="{ errors }" rules="required" name="Email")
                  v-text-field(v-model="profile.email" :placeholder="userData.providerData[0].email" color="secondary" label="Notifications Email*" prepend-icon="mdi-email" validate-on-blur="")
              v-col(cols="10")
                validation-provider(v-slot="{ errors }" rules="required" name="Session password")
                  v-text-field(v-model="profile.session_pass" color="secondary" label="Session password*" prepend-icon="mdi-lock" validate-on-blur="" type="password")
              v-col(cols="2")
                v-tooltip(top="" :open-on-hover="false")
                  template(v-slot:activator="{ on }" )
                    v-btn(icon="" v-on="on").m-0.pa-0
                      v-icon(color="grey lighten-1") mdi-information-outline
                  span After 10 minutes of inactivity the system locks up. The session password is used to unlock it
        // Second
        v-tab-item
          .text-center.display-1.grey--text.font-weight-light.mb-6
          form
            v-row.mx-auto(justify="space-around" style="max-width: 500px;")
              v-col(cols="12" md="8")
                validation-provider(name="address")
                  v-text-field(v-model="profile.address" color="secondary" label="Address" validate-on-blur="")
              v-col(cols="12" md="4")
                validation-provider(name="street")
                  v-text-field(v-model="profile.street" color="secondary" label="Apartment number " validate-on-blur="")
              v-col(cols="12" md="6")
                validation-provider(v-slot="{ errors }" rules="required" name="city")
                  v-text-field(v-model="profile.city" :error-messages="errors" color="secondary" label="City*" validate-on-blur="")
              v-col(cols="12" md="6")
                validation-provider(v-slot="{ errors }" rules="required" name="state")
                  v-autocomplete(v-model="profile.state" :autocomplete="Date.now()" :error-messages="errors" :items="states" color="secondary" label="State*" validate-on-blur="")
        v-tab-item
          .text-center.display-1.grey--text COMPLETE
          form
            v-row.mx-auto(justify="space-around").text-center
              v-col(cols="12")
                v-avatar(size="130")
                  v-icon(color="success" size="64" v-text="'mdi-check'")
                h3.font-weight-light
                  | THANK YOU
                h3.font-weight-light
                  | your account setup has been completed
          h2.text-center.display-2.grey--text SHARE THIS APP WITH YOUR FRIENDS
          v-col.text-center
            ShareNetwork(
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
              ).mx-2
                v-icon(size="40" :color="network.name === 'Facebook' ? 'secondary':'green'") {{network.icon}}
                // span(color="white") {{ network.name }}
</template>

<script>
  import firebase from 'firebase'
  import imageCompression from 'browser-image-compression'
  export default {
    name: 'DashboardFormsWizard',
    data: () => ({
      account: [],
      states: [
        'Alabama', 'Alaska', 'American Samoa', 'Arizona',
        'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'District of Columbia', 'Federated States of Micronesia',
        'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
        'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
        'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
        'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
      ],
      // deeply dev default 0
      tab: 0,
      tabs: ['Account', 'Address', 'Complete'],
      profile: {
        email: '',
        first_name: '',
        last_name: '',
        address: '',
        city: '',
        state: '',
        type: '',
        street: '',
        phone: '',
        image: null,
        roles: [],
        session_pass: '',
      },
    }),
    computed: {
      scope () {
        if (this.tab === 0) return 'Account'
        else if (this.tab === 1) return 'Address'
        else return 'Complete'
      },
      availableSteps () {
        const steps = [0]
        if (
          this.profile.first_name &&
          this.profile.last_name &&
          this.profile.email &&
          this.profile.session_pass
        ) steps.push(1)
        if (
          this.profile.city &&
          this.profile.state &&
          steps.includes(1)
        ) steps.push(2)
        if (
          steps.includes(2)
        ) steps.push(3)
        return steps
      },
      userData () {
        return firebase.auth().currentUser
      },
      sharing () {
        return {
          url: 'https://scenefinder.com/',
          title: 'Scene Finder',
          description: 'Hey, i just downloaded this cool app, called Scene Finder that pays me cash rewards, take a look.',
          quote: 'Hey, i just downloaded this cool app, called Scene Finder that pays me cash rewards, take a look',
          hashtags: 'SceneFinder',
        }
      },
      networks () {
        return [
          { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: '#1877f2' },
          { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' },
        ]
      },
    },
    mounted () {
      const vm = this
      if (vm.userData) {
        vm.profile.first_name = vm.userData.providerData[0].displayName || ''
        vm.profile.email = vm.userData.providerData[0].email || ''
        vm.profile.image = vm.userData.providerData[0].photoURL || ''
        vm.profile.phone = vm.userData.providerData[0].phone || ''
      }
    },
    methods: {
      next (valid) {
        // console.log(valid)
        if (!valid) return valid
        if (this.tab === this.tabs.length - 1) {
          // console.log('save')
          this.save()
        } else {
          // console.log(this.tab, this.tabs.length)
          this.tab++
        }
      },
      uploadToServer (file) {
        const vm = this
        const storageRef = firebase.storage().ref()
        const metadata = {
          contentType: file.type,
          customMetadata: {
            targetType: file.type,
            targetId: vm.userData.uid,
            targetName: file.name,
          },
        }
        // Push to child path.
        // [START oncomplete]
        storageRef.child(`users/${vm.userData.uid}/profile/picture`).put(file, metadata).then(function (snapshot) {
          snapshot.ref.getDownloadURL().then(function (url) {
            vm.profile.image = url
          })
        }).catch(function (error) {
          console.error('Upload failed:', error)
        })
      },
      async onChange (val) {
        const vm = this
        const value = val.target.files[0]
        if (!value) return (this.profile.image = null)
        this.profile.image = URL.createObjectURL(value)
        const imageFile = val.target.files[0]
        // console.log('originalFile instanceof Blob', imageFile instanceof Blob) // true
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 200,
          useWebWorker: true,
          maxIteration: 12,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options)
          // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
          // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
          if (imageFile.size > compressedFile.size) {
            // console.log('compressed is smaller')
            await vm.uploadToServer(compressedFile)
          } else {
            // console.log('original is smaller')
            await vm.uploadToServer(imageFile)
          } // if original is smaller
        } catch (error) {
          // console.log(error)
        }
      },
      save () {
        const vm = this
        vm.profile.complete = true
        vm.$store.dispatch('users/updateProfile', vm.profile).then(function (res) {
          vm.$emit('close')
        })
      },
    },
  }
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-radius: 50%
    border: 2px solid rgba(#808184, .42 )
    .v-icon
      color: inherit
    .v-card--add
      background-color: $secondary
      position: absolute
      border-radius: 50%
      color: white
      width: 25px
      height: 25px
      display: flex
      justify-content: center
      align-items: center
      bottom: 0
      right:0
    .v-image__image
      // border-radius: 50%
  .v-card--account,
  .v-card--account:before
    border-radius: 50%
  .custom-tab
    button
      border-radius: 30px
      width: calc( 50% - 1.5rem )
      height: 63px
      &.success
        background-color: #ff0000 !important
    .v-card__actions.v-card__actions
      padding-bottom: 49px
  .current-tab-0
    .v-card__actions.v-card__actions
      width: max-content
      margin: 0 auto
      padding-bottom: 49px !important
      button
        background-color: #ff0000 !important
      button:nth-child(1)
        display: none
  .current-tab-1
    .v-card__actions
      button
        flex: 0 1 calc( 50% - 1.5rem )
    .v-card.v-card.v-card--account
      border-radius: 5px
      padding: 24px !important
    .v-item-group
      margin: 0
    .v-custom-icon
      width: 100% !important
      height: auto
    .separation
      width: 75%
      margin: 30px auto 20px
</style>
