var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('base-material-wizard',{staticClass:"custom-tab",class:("current-tab-" + _vm.tab),attrs:{"available-steps":_vm.availableSteps,"items":_vm.tabs},on:{"click:next":function($event){return _vm.next(valid)},"click:prev":function($event){_vm.tab--}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"account-tab"},[_c('form',[_c('div',{staticClass:"text-center display-1 font-weight-light mb-6"}),_c('v-row',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onChange}}),_c('v-card',{staticClass:"mx-auto mt-0 d-inline-flex v-card--account",class:_vm.profile.image ? 'success--text' : 'grey--text',attrs:{"outlined":"","height":"106","width":"106"},on:{"click":function($event){return _vm.$refs.file.click()}}},[(_vm.profile.image)?_c('v-img',{staticClass:"custom-avatar",attrs:{"src":_vm.profile.image,"height":"100%","width":"100%"}}):_c('v-icon',{staticClass:"mx-auto",attrs:{"size":"96"}},[_vm._v("mdi-account")]),_c('div',{staticClass:"v-card--add"},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"First name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.userData.providerData[0].displayName,"value":_vm.userData.providerData[0].displayName,"error-messages":errors,"color":"secondary","label":"First Name*","prepend-icon":"mdi-face","validate-on-blur":""},model:{value:(_vm.profile.first_name),callback:function ($$v) {_vm.$set(_vm.profile, "first_name", $$v)},expression:"profile.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Last name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"Last Name*","prepend-icon":"mdi-account","validate-on-blur":""},model:{value:(_vm.profile.last_name),callback:function ($$v) {_vm.$set(_vm.profile, "last_name", $$v)},expression:"profile.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone"}},[_c('v-text-field',{attrs:{"placeholder":_vm.userData.providerData[0].phone,"color":"secondary","label":"Phone","prepend-icon":"mdi-phone","validate-on-blur":"","type":"number"},model:{value:(_vm.profile.phone),callback:function ($$v) {_vm.$set(_vm.profile, "phone", $$v)},expression:"profile.phone"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.userData.providerData[0].email,"color":"secondary","label":"Notifications Email*","prepend-icon":"mdi-email","validate-on-blur":""},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Session password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","label":"Session password*","prepend-icon":"mdi-lock","validate-on-blur":"","type":"password"},model:{value:(_vm.profile.session_pass),callback:function ($$v) {_vm.$set(_vm.profile, "session_pass", $$v)},expression:"profile.session_pass"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"m-0 pa-0",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("After 10 minutes of inactivity the system locks up. The session password is used to unlock it")])])],1)],1)],1)]),_c('v-tab-item',[_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"}),_c('form',[_c('v-row',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":"address"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Address","validate-on-blur":""},model:{value:(_vm.profile.address),callback:function ($$v) {_vm.$set(_vm.profile, "address", $$v)},expression:"profile.address"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"street"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Apartment number ","validate-on-blur":""},model:{value:(_vm.profile.street),callback:function ($$v) {_vm.$set(_vm.profile, "street", $$v)},expression:"profile.street"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":"City*","validate-on-blur":""},model:{value:(_vm.profile.city),callback:function ($$v) {_vm.$set(_vm.profile, "city", $$v)},expression:"profile.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"autocomplete":Date.now(),"error-messages":errors,"items":_vm.states,"color":"secondary","label":"State*","validate-on-blur":""},model:{value:(_vm.profile.state),callback:function ($$v) {_vm.$set(_vm.profile, "state", $$v)},expression:"profile.state"}})]}}],null,true)})],1)],1)],1)]),_c('v-tab-item',[_c('div',{staticClass:"text-center display-1 grey--text"},[_vm._v("COMPLETE")]),_c('form',[_c('v-row',{staticClass:"mx-auto text-center",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"size":"130"}},[_c('v-icon',{attrs:{"color":"success","size":"64"},domProps:{"textContent":_vm._s('mdi-check')}})],1),_c('h3',{staticClass:"font-weight-light"},[_vm._v("THANK YOU")]),_c('h3',{staticClass:"font-weight-light"},[_vm._v("your account setup has been completed")])],1)],1)],1),_c('h2',{staticClass:"text-center display-2 grey--text"},[_vm._v("SHARE THIS APP WITH YOUR FRIENDS")]),_c('v-col',{staticClass:"text-center"},_vm._l((_vm.networks),function(network){return _c('ShareNetwork',{key:network.network,staticClass:"mx-2",attrs:{"network":network.network,"url":_vm.sharing.url,"title":_vm.sharing.title,"description":_vm.sharing.description,"quote":_vm.sharing.quote,"hashtags":_vm.sharing.hashtags}},[_c('v-icon',{attrs:{"size":"40","color":network.name === 'Facebook' ? 'secondary':'green'}},[_vm._v(_vm._s(network.icon))])],1)}),1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }